import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";

interface RequestHandbookTeamParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const postHandbookTeam = async ({ config }: RequestHandbookTeamParams) => {
	try {
		const response = await api.post<TeamType>('/core-service/api/incident/team', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookTeam = async ({ config }: RequestHandbookTeamParams) => {
	try {
		const response = await api.put<TeamType>('/core-service/api/incident/team', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookTeamById = async ({ id }: RequestHandbookTeamParams) => {
	try {
		const response = await api.get<TeamType>(`/core-service/api/incident/team/${id}`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookTeamAll = async () => {
	try {
		const response = await api.get<TeamType[]>(`/core-service/api/incident/team/all`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const deleteHandbookTeam = async ({ id }: RequestHandbookTeamParams) => {
	try {
		const response = await api.delete<TeamType>(`/core-service/api/incident/team/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}




