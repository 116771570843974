import { Button, Flex, FloatButton, Form, FormProps, Input, InputNumber, Modal, Select, Slider, Space, Table, TableProps, Typography } from 'antd'
import { MinusOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ReasonsTable from './reasonsTable/ReasonsTable';
import ActionTable from './action-table/ActionTable';

type FieldType = {
	firstLineResponsible?: Array<string>;
	secondLineResponsible?: Array<string>;
	thirdLineResponsible?: Array<string>;
	investigationTeam?: Array<string>;
	headOfInvestigationTeam?: string;
	monitoringResponsible?: Array<string>;
	physicalPlace?: Array<string>;
	system?: string
	process?: Array<string>
	additionalAdress?: Array<string>,
	description?: string,
	victims?: Array<string>,
	participants?: Array<string>,
	minLoss: number,
	maxLoss: number,
	priority: number,
};



interface priorityDataType {
	priority: string;
	class: string;
	consequences: string;
	frequency: string;
	reactionTime: number;
	closeTime: number
}

type IQualityIncidentSettings = {
	edit: boolean
};

const QualityIncidentSettings
	: React.FC<IQualityIncidentSettings
	> = ({ edit }) => {

		const navigate = useNavigate()

		const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
			console.log('Success:', values);
		};

		const [selectedPriority, setSelectedPriority] = useState<priorityDataType | null>(null)



		const priorityColumns: TableProps<priorityDataType>['columns'] = [
			{
				title: 'Приоритет',
				dataIndex: 'priority',
				key: 'priority',
				render: (text, _, index) => {
					return <span>{`${index + 1} - ${text}`}</span>;
				}
			},
			{
				title: 'Класс',
				dataIndex: 'class',
				key: 'class',
			},
			{
				title: 'Последствия от - до, в $',
				dataIndex: 'consequences',
				key: 'consequences',
			},
			{
				title: 'Частота инцидента, от - до, в месяц',
				dataIndex: 'frequency',
				key: 'frequency',
			},
			{
				title: 'Норматив реации, в секундах',
				dataIndex: 'reactionTime',
				key: 'reactionTime',
				render: (text, data, index) => {
					return (
						<>
							{
								selectedPriority && data.priority === selectedPriority.priority ? (
									<Flex gap='middle' align='center'>
										<InputNumber
											defaultValue={text}
											value={selectedPriority.reactionTime}
											style={{ width: 120 }}
											onChange={(value) =>
												setSelectedPriority({
													...selectedPriority,
													reactionTime: Number(value),
												})
											}
										/>
										{selectedPriority.reactionTime !== text && <span style={{ color: 'red' }}>{data.reactionTime}</span>}
									</Flex>
								) : (
									<span >{text}</span>
								)}
						</>
					);
				},
			},
			{
				title: 'Норматив закрытия, в секундах',
				dataIndex: 'closeTime',
				key: 'closeTime',
				render: (text, data, index) => {
					return (
						<>
							{
								selectedPriority && data.priority === selectedPriority.priority ? (
									<Flex gap='middle' align='center'>
										<InputNumber
											defaultValue={text}
											value={selectedPriority.closeTime}
											style={{ width: 90 }}
											onChange={(value) =>
												setSelectedPriority({
													...selectedPriority,
													closeTime: Number(value),
												})
											}
										/>
										{selectedPriority.closeTime !== text && <span style={{ color: 'red' }}>{data.closeTime}</span>}
									</Flex>
								) : (
									<span >{text}</span>
								)}
						</>
					);
				},
			}
		]

		const priorityDataSource = [
			{
				key: '1',
				priority: 'Высокий',
				class: 'Severity One',
				consequences: '100000 - бесконечность',
				frequency: '5000',
				reactionTime: 5000,
				closeTime: 3600
			},
			{
				key: '2',
				priority: 'Средний',
				class: 'Severity Two',
				consequences: '1000 - 100000',
				frequency: '1001 - 10000',
				reactionTime: 172801,
				closeTime: 500000
			},
			{
				key: '3',
				priority: 'Низкий',
				class: 'Severity Three',
				consequences: '100 - 1000',
				frequency: '5 - 100',
				reactionTime: 172802,
				closeTime: 172802
			},
			{
				key: '4',
				priority: 'Пренебрежимо низкий',
				class: 'Severity Four',
				consequences: '0 - 100',
				frequency: '1-10',
				reactionTime: 172803,
				closeTime: 172803
			},
		];

		return (

			<Form
				// name="basic"
				labelCol={{ span: 10 }}
				// wrapperCol={{ span: 18 }}
				labelAlign='left'
				labelWrap={true}
				style={{ width: 1000, padding: 20 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
			>

				<Typography.Title level={4}>ПРЕДОПРЕДЕЛЕНИЕ ПАРАМЕТРОВ АВТО ЗАЯВОК ПО ПРАВИЛУ [НАЗВАНИЕ ПРАВИЛА], УРОВЕНЬ [ЧИСЛО - ТЕКСТОВОЕ ЗНАЧЕНИЕ]</Typography.Title>

				<Typography.Title level={5}>Роли и/или ответственность</Typography.Title>

				<Form.Item<FieldType>
					label="Ответственные на первой линии"
					name="firstLineResponsible"
					rules={[{ required: true, message: 'Ответственные на первой линии' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные на второй линии"
					name="secondLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные на третьей линии"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Спец. группа расследования"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Руководитель или лидер спец группы рассл"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Ответственные за мониторинг или контроль"
					name="thirdLineResponsible"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Typography.Title level={5}>Место, где произошел инцидент или есть подозрения на него </Typography.Title>

				<Form.Item<FieldType>
					label="Физическое"
					name="physicalPlace"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>


				<Form.Item<FieldType>
					label="Система"
					name="system"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Процесс"
					name="process"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Select />
				</Form.Item>

				<Form.Item<FieldType>
					label="Общее описание сути инцидента"
					name="description"
					rules={[{ required: true, message: 'Please input the field' }]}
				>
					<Input.TextArea />
				</Form.Item>

				{/* <Space style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-between', width: '100%', alignItems: 'flex-start' }} align="baseline"> */}

				<Flex justify='space-between' align='flex-start'>

					<Flex vertical style={{ width: 500 }} gap={'middle'}>

						<Form.Item<FieldType>
							label="Пострадавшие"
							name="victims"
						>
							<Select />
						</Form.Item>
						<Form.Item<FieldType>
							label="Виновные лица"
							name="participants"
						>
							<Select />
						</Form.Item>
					</Flex>

					<Flex vertical align='flex-start'>
						<Typography.Title level={5}>	Сумма потенциальных и/или фактиеских потерь в $ </Typography.Title>


						<Form.Item<FieldType> name={'minLoss'} label="От" rules={[{ type: 'number', min: 0 }]}>
							<InputNumber />
						</Form.Item>

						<Form.Item<FieldType> name={'maxLoss'} label="До" rules={[{ type: 'number', min: 0 }]}>
							<InputNumber />
						</Form.Item>
					</Flex>
					{/* </Space> */}
				</Flex>


				<ActionTable />

				<ReasonsTable />

				<Typography.Title level={5}>Общая стратегия или подход решения инцидента</Typography.Title>

				<Input.TextArea />

				<Typography.Title style={{ marginTop: 12 }} level={5}>Класс и приоритет инцидента</Typography.Title>

				<Table<priorityDataType>
					size='small'
					rowSelection={{
						type: 'radio',
						onChange: (selectedRowKeys: React.Key[], selectedRows: priorityDataType[]) => {
							setSelectedPriority(selectedRows[0])
							console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
						},
					}}
					columns={priorityColumns}
					dataSource={priorityDataSource}
				/>

				<FloatButton
					shape="square"
					style={{ right: 24, bottom: 90 + 24 }}
					icon={<FontAwesomeIcon icon={faArrowLeft} />}
					onClick={() => {
						// navigate(`${URLPaths.MODEL_EDIT.link}/${fromModel}`)
					}}
				/>

				<FloatButton
					icon={<SaveOutlined />}

				/>
			</Form >

		)
	}

export default QualityIncidentSettings
