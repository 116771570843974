import React, { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { URLPaths } from "../config/application/URLPaths";
import { Page404 } from "./404/Page404";
import { Home } from "./Home/Home";
import { usePrivileges } from "../hooks/PrivilegesProvider";
import { FormModels } from "./Model/common/edit/FormModels";
import RuleDefaultForm from "./Model/rules/edit";
import DataStructureForm from "./Model/dataStructures/edit/DataStructureForm";
import ConnectorFormEdit from "./Model/connectors/edit/ConnectorFormEdit";
import { Flex, Layout } from 'antd';
import styles from './MainContainer.module.scss'
import SiderMenu from "../components/SiderMenu/SiderMenu";
import Models from "./Model/common/all/Models";
import Connectors from "./Model/connectors/all/Connectors";
import Rules from "./Model/rules/all/Rules";
import DataStructureList from "./Model/dataStructures/all/DataStructure";
import GeneralHeader from "../components/Header/Header";
import { AuthorizationForm } from "./Forms/Authoization/AuthorizationForm";
import { useQuery } from "@tanstack/react-query";
import RegistrationForm from "./Forms/Registration/RegistrationForm";
import Incidents from "./Incidents/Incidents";
import ManualIncidentCreate from "./Incidents/manual/IncidentModal/ManualIncidentModal";
import Handbook from "./Handbook/Handbook";
import QualityIncidentSettings from "./Model/rules/edit/QualityTable/quality-incident-settings/QualityIncidentSettings";

interface MainContainerProps {
	isAuth: boolean;
}

export const MainContainer: FC<MainContainerProps> = ({ isAuth }) => {

	const [collapsed, setCollapsed] = useState(false);

	// const { data, isError } = useQuery({
	// 	queryKey: ['refreshSession'],
	// 	queryFn: postRefreshUserSession,
	// 	enabled: isAuth,
	// 	refetchOnWindowFocus: false,
	// 	refetchInterval: 200000,
	// });

	// useEffect(() => {
	// 	if (data) {
	// 		sessionStorage.setItem('lastRefreshTime', new Date().getTime().toString())
	// 	}
	// 	else if (isError) {
	// 		console.error('Error refreshing token:', isError)
	// 	}
	// }, [data, isError])

	const { Header, Sider, Content } = Layout;

	return (
		<Layout style={{ backgroundColor: '#fff', minHeight: 'calc(100vh - 70px)' }}>

			<Header className={styles.header} >
				{/* <Navbar /> */}
				<GeneralHeader isAuth={isAuth} />
			</Header>

			<Layout hasSider style={{ position: 'relative', minHeight: '100%' }}>
				{isAuth && (
					<Sider
						theme='light'
						width={300}
						collapsible
						collapsed={collapsed}
						onCollapse={() => setCollapsed(!collapsed)}
						className={styles.sider}
						style={{
							position: "sticky",
							overflow: "auto",
							top: 70,
							height: 'calc(100vh - 75px)',
							left: 0,
						}}
					>

						<SiderMenu collapsed={collapsed} />
					</Sider>
				)}

				<Layout>

					<Content style={{ borderLeft: '1px solid #B3B2B2', background: '#FFF' }}>
						<Flex style={{ width: '100%', height: '100%' }}>
							<Flex style={{ minWidth: '100%', margin: '0 auto', minHeight: '100%' }} vertical>
								{/* <Navbar /> */}
								<Flex style={{ minWidth: '98%', margin: '0 auto', height: '100%', marginRight: 'auto' }} >
									{/* {isAuth && (
										<Sidebar width={300} height="100%" onReady={(sidebar) => setSidebarDHX(sidebar)} />
									)} */}
									<Routes>
										{/*404*/}
										<Route path={URLPaths.INCIDENTS.link} element={<Incidents />} />

										<Route path={"/404"} element={<Page404 />} />
										<Route path={URLPaths.AUTH.link + "/*"} element={<AuthorizationForm />} />
										<Route path={URLPaths.REGFORM.link + "/*"} element={<RegistrationForm />} />
										<Route path={URLPaths.HOME.link} element={<Home isAuth={isAuth} />} />
										{/* {(priv?.USER_VIEW) && */}
										<Route path={URLPaths.MODELS.link} element={<Models />} />
										<Route path={URLPaths.CONNECTORS.link} element={<Connectors />} />
										<Route path={URLPaths.RULES.link} element={<Rules />} />
										<Route path={URLPaths.DATASTRUCTURES.link} element={<DataStructureList />} />
										{/* } */}
										{/* {(priv?.USER_CREATE) && */}
										<>
											<Route path={URLPaths.RULES_CREATE.link} element={<RuleDefaultForm edit={false} />} />
											<Route path={URLPaths.MODEL_CREATE.link} element={<FormModels edit={false} />} />
										</>
										{/* // } */}
										{/* {(priv?.USER_EDIT) && */}
										<>
											{/* <Route path={URLPaths.IN_CONNECTOR_EDIT.link + "/:id"} element={<FormInput edit={true} />} /> */}
											<Route path={URLPaths.IN_CONNECTOR_EDIT.link + "/:id"} element={<ConnectorFormEdit edit={true} connectorType="input" />} />
											<Route path={URLPaths.IN_CONNECTOR_EDIT.link + "/:id" + "/:fromModel"} element={<ConnectorFormEdit edit={true} connectorType="input" />} />
											<Route path={URLPaths.OUT_CONNECTOR_EDIT.link + "/:id"} element={<ConnectorFormEdit edit={true} connectorType="output" />} />
											<Route path={URLPaths.OUT_CONNECTOR_EDIT.link + "/:id" + "/:fromModel"} element={<ConnectorFormEdit edit={true} connectorType="output" />} />
											<Route path={URLPaths.HANDBOOK.link} element={<Handbook />} />

											{/* <Route path={URLPaths.RULES_EDIT.link + "/:id"} element={<FormRule edit={true} />} /> */}
											<Route
												path={`${URLPaths.RULES_EDIT.link}/:id/:fromModel`}
												element={<RuleDefaultForm edit={true} />}
											/>
											<Route path={URLPaths.RULES_SETTINGS.link + "/:id" + "/:fromModel"} element={<QualityIncidentSettings edit={true} />} />
											<Route path={URLPaths.MODEL_EDIT.link + "/:id"} element={<FormModels edit={true} />} />
											<Route path={URLPaths.DATASTRUCTURE_EDIT.link + "/:id"} element={<DataStructureForm edit={true} />} />

										</>

										<Route path={URLPaths.MANUAL_INCIDENTS.link} element={<ManualIncidentCreate />} />
										<Route path={URLPaths.HANDBOOK.link} element={<Handbook />} />

										{/* } */}
										{/* <Route path={URLPaths.REGFORM.link} element={<RegForm />} /> */}
									</Routes>
								</Flex>
							</Flex>
						</Flex>
					</Content>
				</Layout>
			</Layout>

		</Layout>
	)
}


