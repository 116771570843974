import { Button, Flex, Form, FormProps, Input, Modal, Select, Table } from 'antd'
import { useState } from 'react'
import { useDeleteHandbookTeam, useFetchAllHandbookTeamQuery, usePostHandbookTeam, useUpdateHandbookTeam } from '../../../shared/queries/handbook/team/useTeam';
import { useFetchAllHandbookInvolvedPartiesType } from '../../../shared/queries/handbook/involvedPartiesType/useInvolvedPartiesType';

function Teams() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
	const [currentRowData, setCurrentRowData] = useState<TeamType | null>(null)
	const [form] = Form.useForm();

	const list = useFetchAllHandbookInvolvedPartiesType()
	const create = usePostHandbookTeam();
	const update = useUpdateHandbookTeam();
	const remove = useDeleteHandbookTeam()
	const { data } = useFetchAllHandbookTeamQuery()

	const closeModal = () => setIsModalOpen(false)

	const handleSave: FormProps<TeamType>['onFinish'] = async (values) => {
		try {
			if (isCreate) {
				await create.mutateAsync({
					...values,
					type: values.type?.map((id: InvolvedPartiesType) => ({ id })) || [],
					isDel: true,
					isSystem: false
				});

			} else {
				await update.mutateAsync({
					...values, id: currentRowData?.id,
					type: values.type?.map((id: InvolvedPartiesType) => ({ id })) || [],
					isDel: true,
					isSystem: false
				});
			}

			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};

	const columns = [
		{
			title: 'Команда',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Принадлежность к виду вовлечннной стороны',
			dataIndex: 'type',
			key: 'type',
			render: (items: Array<TeamType>) => {
				// Если items — массив объектов, отображаем только `type`
				return items?.map((item) => item.type).join(', ') || '-';
			},
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button onClick={() => {
					setCreate(true)
					setIsModalOpen(true)
					form.setFieldsValue(undefined);
				}}>Добавить</Button>
				<Button onClick={() => {
					setCreate(false)
					setIsModalOpen(true)
					form.setFieldsValue(currentRowData);
				}}>Открыть существующего</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={data}
				rowKey={(data: TeamType) => Number(data.id)}
				onRow={(record) => {
					return {
						onClick: () => {
							setSelectedRowId(record.id)
							setCurrentRowData(record)
						},
					};
				}
				}
				rowClassName={(record) => (record.id === selectedRowId ? 'pickedTableRow' : 'defaultTableRow')}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						<Button onClick={() => {
							if (selectedRowId) {
								remove.mutate(selectedRowId)
								closeModal()
							}
						}
						} danger>
							Удалить
						</Button>
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>
				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<OrganizationType>
						name="title"
						label="Организация/подразделение"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<InvolvedPartiesType>
						label="Принадлежность к другому виду вовлеченнной стороны"
						name="type"
					>
						<Select
							mode="multiple"
							onClick={() => list.mutate()}
							options={list.data?.map((item) => ({
								label: item.type,
								value: item.id,
							}))}
							loading={list.isPending}
							placeholder="Выберите опцию"
						/>


					</Form.Item>
				</Form>

			</Modal>

		</Flex>
	)
}

export default Teams