
import type { TableProps } from 'antd';


export const incidentColumns: TableProps<IncidentType>['columns'] = [
	{
		title: '#',
		dataIndex: 'id',
		key: 'id',
	},
	{
		title: 'Дата и время заявки',
		dataIndex: 'startTime',
		key: 'startTime',
		render: (startTime: string) => new Date(startTime).toLocaleString("ru-RU") || 'Не указано',
	},
	{
		title: 'Автор заявки',
		dataIndex: 'problem',
		key: 'problem',
		render: (problem: string) => problem || 'Не указано',
	},
	{
		title: 'Место происшествия инцидента',
		dataIndex: 'divisions',
		key: 'divisions',
		render: (divisions: IDivision[]) =>
			divisions?.map((division) => division.title).join(', ') || 'Не указано',
	},
	{
		title: 'Наименование сценария',
		dataIndex: 'problem',
		key: 'problem',
		render: (problem: string) => problem || 'Не указано',
	},
	{
		title: 'Основной признак',
		dataIndex: 'causes',
		key: 'causes',
		render: (causes: Array<{ id?: number; title: string }>) =>
			causes?.map((cause) => cause.title).join(', ') || 'Не указано',
	},
	{
		title: 'Детектор',
		dataIndex: 'detectorId',
		key: 'detectorId',
		render: (detectorId: number) => detectorId || 'Не указано',
	},
	{
		title: 'Время обнаружения',
		dataIndex: 'detecxtionTime',
		key: 'detecxtionTime',
		render: (detecxtionTime: string) => detecxtionTime || 'Не указано',
	},
	{
		title: 'Приоритет',
		dataIndex: 'priority',
		key: 'priority',
		render: (priority: PriorityType | null | undefined) =>
			priority?.title || 'Не указано',
	},
	{
		title: 'Время реакции (макс)',
		dataIndex: 'number',
		key: 'number',
		render: (number: number) => number || 'Не указано',
	},
	{
		title: 'Ответственные',
		dataIndex: 'firstResponsibleId',
		key: 'firstResponsibleId',
		render: (responsibles: { id?: number; title: string; firstName: string }[]) =>
			responsibles?.map((responsible) => responsible.firstName).join(', ') || 'Не указано',
	},
	{
		title: 'Состояние',
		dataIndex: 'status',
		key: 'status',
		render: (status: "CREATED" | "CLOSED") => {
			switch (status) {
				case "CREATED":
					return "Создано";
				case "CLOSED":
					return "Закрыто";
				default:
					return "Не указано";
			}
		},
	},
];
