import { Button, Flex, Form, FormProps, Input, Modal, Select, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useDeleteHandbookProcess, useFetchAllHandbookProcessQuery, usePostHandbookProcess, useUpdateHandbookProcess } from '../../../shared/queries/handbook/process/useProcess';
import { useFetchAllHandbookOrganizationType } from '../../../shared/queries/handbook/organization/useOrganization';
import { useFetchAllHandbookTeam } from '../../../shared/queries/handbook/team/useTeam';
import { useFetchAllHandbookPeople } from '../../../shared/queries/handbook/people/usePeople';

function Processes() {

	const { data } = useFetchAllHandbookProcessQuery()
	const create = usePostHandbookProcess();
	const update = useUpdateHandbookProcess();
	const remove = useDeleteHandbookProcess()
	const organizationsList = useFetchAllHandbookOrganizationType()
	const teamsList = useFetchAllHandbookTeam()
	const peopleList = useFetchAllHandbookPeople()

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [form] = Form.useForm();
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
	const [currentRowData, setCurrentRowData] = useState<ProcessType | null>(null)
	const [list, setList] = useState<Array<{ id: number, title: string }>>([])

	useEffect(() => {
		if (organizationsList.data && teamsList.data && peopleList.data) {
			setList([
				...organizationsList.data
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: item.title })),
				...teamsList.data
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: item.title })),
				...peopleList.data
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: `${item.firstName} ${item.middleName}` })),
			]);
		}
	}, [organizationsList.data, teamsList.data, peopleList.data]);




	const closeModal = () => setIsModalOpen(false)

	const columns = [
		{
			title: 'Процесс',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Владельцы процесса',
			dataIndex: 'owners',
			key: 'owners',
			render: (owners: { id: number; title: string }[]) =>
				owners?.map((child) => child.title).join(', '),
		},
		{
			title: 'Участники процесса',
			dataIndex: 'participants',
			key: 'participants',
			render: (participants: { id: number; title: string }[]) =>
				participants?.map((child) => child.title).join(', '),
		},
	];

	const handleSave: FormProps<ProcessType>['onFinish'] = async (values) => {
		try {
			if (isCreate) {
				await create.mutateAsync({
					...values,
					owners: values.owners.map((item: string) => ({ id: item })),
					participants: values.participants?.map((item: string) => ({ id: item })),
				});

			} else {
				await update.mutateAsync({
					...values,
					owners: values.owners.map((item: string) => ({ id: item })),
					participants: values.participants?.map((item: string) => ({ id: item })),
					id: selectedRowId
				});
			}

			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button onClick={() => {
					setCreate(true)
					setIsModalOpen(true)
				}}>Добавить</Button>
				<Button onClick={() => {
					setCreate(false)
					setIsModalOpen(true)
					form.setFieldsValue(currentRowData)
				}}>Открыть существующего</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={data}
				rowKey={(data: ProcessType) => Number(data.id)}
				onRow={(record) => {
					return {
						onClick: () => {
							setSelectedRowId(record.id)
							setCurrentRowData(record)
						},
					}
				}}
				rowClassName={(record) => (record.id === selectedRowId ? 'pickedTableRow' : 'defaultTableRow')}

			/>

			<Modal
				title={isCreate ? "Создать" : "Редактировать"}
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						{isCreate ?
							<div></div> :
							<Button onClick={() => {
								if (selectedRowId)
									remove.mutate(selectedRowId)
								closeModal()
							}} danger>
								Удалить
							</Button>}
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>

				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<ProcessType>
						name="title"
						label="Процесс"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input defaultValue={!isCreate ? currentRowData?.title : ''} />
					</Form.Item>

					<Form.Item<ProcessType>
						name="owners"
						label="Владельцы процесса"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Select
							mode="multiple"
							onClick={() => {
								organizationsList.mutate()
								teamsList.mutate()
								peopleList.mutate()
							}}
							options={list?.map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							loading={organizationsList.isPending || teamsList.isPending || peopleList.isPending}
							placeholder="Выберите опцию"
						/>
					</Form.Item>

					<Form.Item<ProcessType>
						name="participants"
						label="Участники процесса"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Select
							mode="multiple"
							onClick={() => {
								organizationsList.mutate()
								teamsList.mutate()
								peopleList.mutate()
							}}
							options={list?.map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							loading={organizationsList.isPending || teamsList.isPending || peopleList.isPending}
							placeholder="Выберите опцию"
						/>
					</Form.Item>

				</Form>
			</Modal>

		</Flex>
	)
}

export default Processes