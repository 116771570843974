import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";

interface RequestHandbookPriorityParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const postHandbookPriority = async ({ config }: RequestHandbookPriorityParams) => {
	try {
		const response = await api.post<PriorityType>('/core-service/api/incident/priority', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookPriority = async ({ config }: RequestHandbookPriorityParams) => {
	try {
		const response = await api.put<PriorityType>('/core-service/api/incident/priority', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookPriorityById = async ({ id }: RequestHandbookPriorityParams) => {
	try {
		const response = await api.get<PriorityType>(`/core-service/api/incident/priority/${id}`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookPriorityAll = async () => {
	try {
		const response = await api.get<PriorityType[]>(`/core-service/api/incident/priority/all`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const deleteHandbookPriority = async ({ id }: RequestHandbookPriorityParams) => {
	try {
		const response = await api.delete<PriorityType>(`/core-service/api/incident/priority/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}




