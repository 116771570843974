import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';
import { deleteHandbookPeople, getHandbookPeopleAll, postHandbookPeople, putHandbookPeople } from "../../../../api/requests/handbook/people";

export function usePostHandbookPeople() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postHandbookPeople({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['peopleList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateHandbookPeople() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putHandbookPeople({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['peopleList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useFetchAllHandbookPeople() {

	return {
		...useMutation({
			mutationFn: () => getHandbookPeopleAll(),
		})
	};
}



export function useFetchAllHandbookPeopleQuery() {
	return useQuery<PeopleType[]>({
		queryKey: ['peopleList'],
		queryFn: getHandbookPeopleAll
	})
}

export function useDeleteHandbookPeople() {

	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (id: number) => deleteHandbookPeople({ id }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Поле успешно удалено!', key: uuidv4() });
			},
			onSettled: async (_: any, error: any) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['peopleList'] })
				}
			},
		}),
		contextHolder: toast.contextHolder
	};
}