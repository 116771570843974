import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';
import { deleteHandbookInvolvedPartiesById, getHandbookInvolvedPartiesAll, postHandbookInvolvedParties, putHandbookInvolvedParties } from "../../../../api/requests";

export function usePostHandbookInvolvedPartiesType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postHandbookInvolvedParties({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['involvedPartiesTypeList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateHandbookInvolvedPartiesType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putHandbookInvolvedParties({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['involvedPartiesTypeList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useFetchAllHandbookInvolvedPartiesType() {
	return {
		...useMutation({
			mutationFn: () => getHandbookInvolvedPartiesAll(),
		})
	};
}

export function useDeleteHandbookInvolvedPartiesType() {

	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (id: number) => deleteHandbookInvolvedPartiesById({ id }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Поле успешно удалено!', key: uuidv4() });
			},
			onSettled: async (_: any, error: any) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['involvedPartiesTypeList'] })
				}
			},
		}),

		contextHolder: toast.contextHolder
	};
}

export function useFetchAllHandbookInvolvedPartiesTypeQuery() {
	return useQuery<InvolvedPartiesType[]>({
		queryKey: ['involvedPartiesTypeList'],
		queryFn: getHandbookInvolvedPartiesAll
	})
}