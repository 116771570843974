import { Button, Flex, Input, Modal, Table, Form, FormProps, Select } from 'antd'
import { useState } from 'react'
import { useDeleteHandbookInvolvedPartiesType, useFetchAllHandbookInvolvedPartiesType, useFetchAllHandbookInvolvedPartiesTypeQuery, usePostHandbookInvolvedPartiesType, useUpdateHandbookInvolvedPartiesType } from '../../../shared/queries/handbook/involvedPartiesType/useInvolvedPartiesType';


function ParticipantType() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [form] = Form.useForm();
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
	const [currentRowData, setCurrentRowData] = useState<InvolvedPartiesType | null>(null)


	const closeModal = () => {
		setIsModalOpen(false);
		form.resetFields();
	};

	const create = usePostHandbookInvolvedPartiesType();
	const update = useUpdateHandbookInvolvedPartiesType();
	const deleteField = useDeleteHandbookInvolvedPartiesType()
	const list = useFetchAllHandbookInvolvedPartiesType()
	const { data } = useFetchAllHandbookInvolvedPartiesTypeQuery()

	const handleSave: FormProps<InvolvedPartiesType>['onFinish'] = async (values) => {
		try {
			const transformedValues = {
				...values,
				del: false,
				system: true,
				parent: values.parent?.map((item: string) => ({ id: item })),
			};

			if (isCreate) {
				await create.mutateAsync(transformedValues);
			} else {
				await update.mutateAsync({ ...transformedValues, id: currentRowData?.id });
			}

			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};


	const columns = [
		{
			title: 'Вид вовлеченной стороны',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Короткое наименование вида',
			dataIndex: 'shortType',
			key: 'shortType',
		},
		{
			title: 'Принадлежность к другому виду вовлеченнной стороны',
			dataIndex: 'parent',
			key: 'parent',
			render: (parent: { id: number; type: string }[]) =>
				parent?.map((child) => child.type).join(', '),
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button onClick={() => {
					setCreate(true)
					setIsModalOpen(true)
				}}>Добавить</Button>
				<Button
					disabled={selectedRowId === undefined}
					onClick={() => {
						setCreate(false)
						setIsModalOpen(true)
						form.setFieldsValue(currentRowData);
					}}>Открыть существующий</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={data}
				rowKey={(data: InvolvedPartiesType) => Number(data.id)}
				onRow={(record) => {
					return {
						onClick: () => {
							setSelectedRowId(record.id)
							setCurrentRowData(record)
						},
					};
				}
				}
				rowClassName={(record) => (record.id === selectedRowId ? 'pickedTableRow' : 'defaultTableRow')}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}

				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						{isCreate || <Button onClick={() => {
							if (selectedRowId) {
								deleteField.mutate(selectedRowId)
								closeModal()
							}
						}} danger>
							Удалить
						</Button>}
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>

				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<InvolvedPartiesType>
						label="Вид вовлеченной стороны"
						name="type"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<InvolvedPartiesType>
						label="Короткое наименование вида"
						name="shortType"
					>
						<Input />
					</Form.Item>

					<Form.Item<InvolvedPartiesType>
						label="Принадлежность к другому виду вовлеченнной стороны"
						name="parent"
					>
						<Select
							mode="multiple"
							onClick={() => list.mutate()} // Запускаем запрос
							options={list.data?.map((item) => ({
								label: item.type,
								value: item.id,
							}))}
							loading={list.isPending}
							placeholder="Выберите опцию"
						/>

					</Form.Item>
				</Form>
			</Modal>

		</Flex>
	)
}

export default ParticipantType