import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';
import { deleteHandbookDivision, deleteHandbookOrganization, getHandbookDivisionAll, getHandbookOrganizationAll, postHandbookDivision, postHandbookOrganization, putHandbookDivision, putHandbookOrganization } from "../../../../api/requests/handbook/organization";

export function usePostHandbookOrganizationType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postHandbookOrganization({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateHandbookOrganizationType() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putHandbookOrganization({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'added!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useFetchAllHandbookOrganizationType() {

	return {
		...useMutation({
			mutationFn: () => getHandbookOrganizationAll(),
		})
	};
}

export function useFetchAllHandbookOrganizationQuery() {
	return useQuery<OrganizationType[]>({
		queryKey: ['organizationList'],
		queryFn: getHandbookOrganizationAll
	})
}

export function useDeleteHandbookOrganization() {

	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (id: number) => deleteHandbookOrganization({ id }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Поле успешно удалено!', key: uuidv4() });
			},
			onSettled: async (_: any, error: any) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			},
		}),

		contextHolder: toast.contextHolder
	};
}


// Divisions

export function usePostHandbookDivision() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postHandbookDivision({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateHandbookDivision() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putHandbookDivision({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'added!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useDeleteHandbookDivision() {

	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (id: number) => deleteHandbookDivision({ id }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Поле успешно удалено!', key: uuidv4() });
			},
			onSettled: async (_: any, error: any) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['organizationList'] })
				}
			},
		}),
		contextHolder: toast.contextHolder
	};
}


export function useFetchAllHandbookDivision() {

	return {
		...useMutation({
			mutationFn: () => getHandbookDivisionAll(),
		})
	};
}

export function useFetchAllHandbookDivisionQuery() {

	return useQuery<IDivision[]>({
		queryKey: ['divisionList'],
		queryFn: getHandbookDivisionAll
	})
}