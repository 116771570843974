import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";

interface RequestHandbookProcessParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const postHandbookProcess = async ({ config }: RequestHandbookProcessParams) => {
	try {
		const response = await api.post<ProcessType>('/core-service/api/incident/process', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookProcess = async ({ config }: RequestHandbookProcessParams) => {
	try {
		const response = await api.put<ProcessType>('/core-service/api/incident/process', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookProcessById = async ({ id }: RequestHandbookProcessParams) => {
	try {
		const response = await api.get<ProcessType>(`/core-service/api/incident/process/${id}`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookProcessAll = async () => {
	try {
		const response = await api.get<ProcessType[]>(`/core-service/api/incident/process/all`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const deleteHandbookProcess = async ({ id }: RequestHandbookProcessParams) => {
	try {
		const response = await api.delete<ProcessType>(`/core-service/api/incident/process/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}
