import { Button, Flex, Form, FormProps, Input, Modal, Select, Table } from 'antd'
import { useState } from 'react';
import { useFetchAllHandbookInvolvedPartiesType } from '../../../shared/queries/handbook/involvedPartiesType/useInvolvedPartiesType';
import { useDeleteHandbookDivision, useDeleteHandbookOrganization, useFetchAllHandbookOrganizationQuery, usePostHandbookDivision, usePostHandbookOrganizationType, useUpdateHandbookDivision, useUpdateHandbookOrganizationType } from '../../../shared/queries/handbook/organization/useOrganization';
import { PlusOutlined } from '@ant-design/icons';

function Organization() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [isDivision, setDivision] = useState(false)
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
	const [currentRowData, setCurrentRowData] = useState<OrganizationType | null>(null)
	const [form] = Form.useForm();

	const list = useFetchAllHandbookInvolvedPartiesType()
	const create = usePostHandbookOrganizationType();
	const update = useUpdateHandbookOrganizationType();
	const deleteOrganization = useDeleteHandbookOrganization()
	const createDivision = usePostHandbookDivision()
	const updateDivision = useUpdateHandbookDivision()
	const deleteDivision = useDeleteHandbookDivision()
	const { data } = useFetchAllHandbookOrganizationQuery()

	const closeModal = () => setIsModalOpen(false)

	const handleDelete = () => {
		if (selectedRowId) {
			isDivision ?
				deleteDivision.mutate(selectedRowId) :
				deleteOrganization.mutate(selectedRowId)
		}
		closeModal()
	}

	const handleSave: FormProps<OrganizationType>['onFinish'] = async (values) => {
		try {
			if (isCreate) {
				isDivision ?
					await createDivision.mutateAsync({
						...values,
						organizationId: selectedRowId,
						involvedPartiesTypes: values.involvedPartiesTypes.map((id: InvolvedPartiesType) => ({ id })),
					}) :
					await create.mutateAsync({
						...values,
						involvedPartiesTypes: values.involvedPartiesTypes.map((id: InvolvedPartiesType) => ({ id })),
					})
			} else {
				isDivision ?
					await updateDivision.mutateAsync({
						...values,
						involvedPartiesTypes: values.involvedPartiesTypes.map((id: InvolvedPartiesType) => ({ id })),
					}) :
					await update.mutateAsync({
						...values,
						involvedPartiesTypes: values.involvedPartiesTypes.map((id: InvolvedPartiesType) => ({ id })),
						id: currentRowData && currentRowData.id
					})
			}

			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};

	const columns = [
		{
			title: 'Организация/подразделение',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Короткое наименование',
			dataIndex: 'shortTitle',
			key: 'shortTitle',
		},
		{
			title: 'Принадлежность к виду вовлечннной стороны',
			dataIndex: 'involvedPartiesTypes',
			key: 'involvedPartiesTypes',
			render: (items: Array<InvolvedPartiesType>) => {
				// Если items — массив объектов, отображаем только `type`
				return items?.map((item) => item.type).join(', ') || '-';
			},
		}
	];

	return (
		<Flex vertical>

			<Flex justify='space-between'>
				{selectedRowId &&

					<Button
						icon={<PlusOutlined />}
						onClick={() => {
							setDivision(true)
							setCreate(true)
							setIsModalOpen(true)
						}}
					/>

				}

				<Flex gap='middle'>
					<Button onClick={() => {
						setCreate(true)
						setIsModalOpen(true)
						setDivision(false)
					}}>Добавить</Button>
					<Button
						disabled={selectedRowId === undefined}
						onClick={() => {
							setIsModalOpen(true)
							setCreate(false)
							setDivision(false)
							form.setFieldsValue(currentRowData);
						}}>Открыть существующий</Button>
				</Flex>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={data?.map((item) => ({ ...item, children: item.divisions }))}
				expandable={{
					defaultExpandAllRows: true,
				}}
				rowKey={(data: OrganizationType) => Number(data.id)}
				onRow={(record) => {
					return {
						onClick: () => {
							setSelectedRowId(record.id)
							setCurrentRowData(record)
						},
					};
				}
				}
				rowClassName={(record) => (record.id === selectedRowId ? 'pickedTableRow' : 'defaultTableRow')}
			/>

			<Modal
				title={isCreate ? "Создать" : "Редактировать"}
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						{isCreate ?
							<div></div> :
							<Button onClick={handleDelete} danger>
								Удалить
							</Button>}
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>

				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<OrganizationType>
						name="title"
						label="Организация/подразделение"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<OrganizationType>
						name="shortTitle"
						label="Короткое наименование"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>


					<Form.Item<OrganizationType>
						label="Принадлежность к другому виду вовлеченнной стороны"
						name="involvedPartiesTypes"
					>
						<Select
							mode="multiple"
							onClick={() => list.mutate()}
							options={list.data?.map((item) => ({
								label: item.type, // Отображаемое название
								value: item.id,   // Уникальный идентификатор
							}))}
							loading={list.isPending}
							placeholder="Выберите опцию"

						/>

					</Form.Item>
				</Form>
			</Modal>

		</Flex>
	)
}

export default Organization