import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../../../../components/CustomToast/useCustomToast";
import { v4 as uuidv4 } from 'uuid';
import { deleteIncidents, getIncidentsList, postIncidents, putIncidents } from "../../../../api/requests/handbook/incident";

export function usePostIncidents() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (config: any) => postIncidents({ config }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'success!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['incidents'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}

export function useUpdateIncidents() {
	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (data: any) => putIncidents({ config: data }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Welcome!', key: uuidv4() });
			},
			onSettled: async (_, error) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['incidents'] })
				}
			}
		}),
		contextHolder: toast.contextHolder
	};
}



export function useDeleteIncidents() {

	const toast = useCustomToast();
	const queryClient = useQueryClient()

	return {
		...useMutation({
			mutationFn: (id: number) => deleteIncidents({ id }),
			onSuccess: () => {
				toast.handleToast({ type: 'success', text: 'Поле успешно удалено!', key: uuidv4() });
			},
			onSettled: async (_: any, error: any) => {
				if (error) {
					toast.handleToast({ type: 'error', text: `Error`, key: uuidv4() });
				}
				else {
					await queryClient.invalidateQueries({ queryKey: ['incidents'] })
				}
			},
		}),
		contextHolder: toast.contextHolder
	};
}


export function useFetchAllIncidentsQuery() {
	return useQuery<IncidentType[]>({
		queryKey: ['incidents'],
		queryFn: getIncidentsList
	})
}
