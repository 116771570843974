import { Button, Flex, Form, FormProps, Input, Modal, Select, Table } from 'antd'
import { useState } from 'react'
import { useFetchAllHandbookInvolvedPartiesType } from '../../../shared/queries/handbook/involvedPartiesType/useInvolvedPartiesType';
import { useDeleteHandbookPeople, useFetchAllHandbookPeopleQuery, usePostHandbookPeople, useUpdateHandbookPeople } from '../../../shared/queries/handbook/people/usePeople';
import { useFetchAllHandbookOrganizationType } from '../../../shared/queries/handbook/organization/useOrganization';
import { useFetchAllHandbookTeam } from '../../../shared/queries/handbook/team/useTeam';

function People() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreate, setCreate] = useState(false)
	const [selectedRowId, setSelectedRowId] = useState<number | undefined>()
	const [currentRowData, setCurrentRowData] = useState<PeopleType | null>(null)

	const [form] = Form.useForm();
	const involevedPartiesTypelist = useFetchAllHandbookInvolvedPartiesType()
	const organizationsList = useFetchAllHandbookOrganizationType()
	const teamsList = useFetchAllHandbookTeam()
	const create = usePostHandbookPeople();
	const update = useUpdateHandbookPeople();
	const remove = useDeleteHandbookPeople()
	const { data } = useFetchAllHandbookPeopleQuery()

	const closeModal = () => setIsModalOpen(false)

	const handleSave: FormProps<PeopleType>['onFinish'] = async (values) => {
		try {
			if (isCreate) {
				await create.mutateAsync({
					...values,
					involvedPartiesType: values.involvedPartiesType?.map((id: InvolvedPartiesType) => ({ id })) || [],
					organizations: values.organizations?.map((id: OrganizationType) => ({ id })) || [],
					teams: values.teams?.map((id: TeamType) => ({ id })) || [],
				});

			} else {
				await update.mutateAsync({
					...values,
					involvedPartiesType: values.involvedPartiesType?.map((id: InvolvedPartiesType) => ({ id })) || [],
					organizations: values.organizations?.map((id: OrganizationType) => ({ id })) || [],
					teams: values.teams?.map((id: TeamType) => ({ id })) || [],
					id: selectedRowId
				});
			}

			closeModal();
		} catch (error) {
			console.error('Ошибка сохранения:', error);
		}
	};

	const columns = [
		{
			title: 'Фамилия',
			dataIndex: 'middleName',
			key: 'middleName',
		},
		{
			title: 'Имя',
			dataIndex: 'firstName',
			key: 'firstName',
		},
		{
			title: 'Отчество',
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: 'Принадлежность лица к виду вовл. cтороны',
			dataIndex: 'involvedPartiesType',
			key: 'involvedPartiesType',
			render: (items: Array<InvolvedPartiesType>) => {
				// Если items — массив объектов, отображаем только `type`
				return items?.map((item) => item.type).join(', ') || '-';
			},
		},
		{
			title: 'Организация/подразделение',
			dataIndex: 'organizations',
			key: 'organizations',
			render: (items: Array<OrganizationType>) => {
				// Если items — массив объектов, отображаем только `type`
				return items?.map((item) => item.title).join(', ') || '-';
			},
		},
		{
			title: 'Команда',
			dataIndex: 'teams',
			key: 'teams',
			render: (items: Array<TeamType>) => {
				// Если items — массив объектов, отображаем только `type`
				return items?.map((item) => item.title).join(', ') || '-';
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
	];

	return (
		<Flex vertical>
			<Flex align='flex-end' justify='end' gap='middle'>
				<Button onClick={() => {
					setCreate(true)
					setIsModalOpen(true)
					form.setFieldsValue(null);
				}}>Добавить</Button>
				<Button onClick={() => {
					setCreate(false)
					setIsModalOpen(true)
					form.setFieldsValue(currentRowData);
				}}>Открыть существующего</Button>
			</Flex>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={data}
				rowKey={(data: PeopleType) => Number(data.id)}
				onRow={(record) => {
					return {
						onClick: () => {
							setSelectedRowId(record.id)
							setCurrentRowData(record)
						},
					};
				}
				}
				rowClassName={(record) => (record.id === selectedRowId ? 'pickedTableRow' : 'defaultTableRow')}
			/>

			<Modal
				title="Редактировать"
				width={800}
				open={isModalOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={
					<Flex justify='space-between'>
						<Button onClick={() => {
							if (selectedRowId)
								remove.mutate(selectedRowId)
							closeModal()
						}} danger >
							Удалить
						</Button>
						<Flex gap='middle' align='end' style={{ textAlign: 'right' }}>
							<Button onClick={closeModal} >
								Отмена
							</Button>
							<Button onClick={() => form.submit()}>
								Сохранить
							</Button>
						</Flex>
					</Flex>
				}>
				<Form
					form={form}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: '100%' }}
					labelWrap={true}
					labelAlign='left'
					onFinish={handleSave}
					initialValues={{ remember: true }}
					autoComplete="off"
				>
					<Form.Item<PeopleType>
						name="firstName"
						label="Имя"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<PeopleType>
						name="middleName"
						label="Фамилия"
						rules={[{ required: true, message: 'Please input field!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item<PeopleType>
						name="lastName"
						label="Отчество"
					>
						<Input />
					</Form.Item>

					<Form.Item<PeopleType>
						label="Принадлежность к другому виду вовлеченнной стороны"
						name="involvedPartiesType"
					>
						<Select
							mode="multiple"
							onClick={() => involevedPartiesTypelist.mutate()}
							options={involevedPartiesTypelist.data?.map((item) => ({
								label: item.type,
								value: item.id,
							}))}
							loading={involevedPartiesTypelist.isPending}
							placeholder="Выберите опцию"
						/>
					</Form.Item>

					<Form.Item<PeopleType>
						label="Организация/подразделение"
						name="organizations"
					>
						<Select
							mode="multiple"
							onClick={() => organizationsList.mutate()}
							options={organizationsList.data?.map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							loading={organizationsList.isPending}
							placeholder="Выберите опцию"
						/>
					</Form.Item>

					<Form.Item<PeopleType>
						label="Команда"
						name="teams"
					>
						<Select
							mode="multiple"
							onClick={() => teamsList.mutate()}
							options={teamsList.data?.map((item) => ({
								label: item.title,
								value: item.id,
							}))}
							loading={teamsList.isPending}
							placeholder="Выберите опцию"
						/>
					</Form.Item>

					<Form.Item<PeopleType>
						name="email"
						label="email"
					>
						<Input />
					</Form.Item>
				</Form>

			</Modal>

		</Flex>
	)
}

export default People