import { Checkbox, DatePicker, Flex, FloatButton, Form, FormProps, Input, InputNumber, Select, Space, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import SelectFormItem from './select-formItem/SelectFormItem';
import ActionTable from '../../../Model/rules/edit/QualityTable/quality-incident-settings/action-table/ActionTable';
import ReasonsTable from '../../../Model/rules/edit/QualityTable/quality-incident-settings/reasonsTable/ReasonsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SaveOutlined } from '@ant-design/icons';
import { useFetchAllHandbookDivision, useFetchAllHandbookDivisionQuery, useFetchAllHandbookOrganizationQuery, useFetchAllHandbookOrganizationType } from '../../../../shared/queries/handbook/organization/useOrganization';
import { useFetchAllHandbookTeam, useFetchAllHandbookTeamQuery } from '../../../../shared/queries/handbook/team/useTeam';
import { useFetchAllHandbookPeople, useFetchAllHandbookPeopleQuery } from '../../../../shared/queries/handbook/people/usePeople';
import { useFetchAllHandbookPriotityQuery } from '../../../../shared/queries/handbook/priority/usePriority';
import { usePostIncidents } from '../../../../shared/queries/handbook/incidents/useIncidents';
import { useForm } from 'antd/es/form/Form';
import { useFetchAllHandbookProcess, useFetchAllHandbookProcessQuery } from '../../../../shared/queries/handbook/process/useProcess';



const ManualIncidentCreate = () => {

	const [list, setList] = useState<Array<{ id: number, title: string }>>([])
	const [group, setGroup] = useState<Array<{ id: number, title: string }>>([])
	const [place, setPlace] = useState<Array<{ id: number, title: string }>>([])
	const [loading, setLoading] = useState(false)


	const [selectedPriorityRowId, setSelectedPriorityRowId] = useState<number | undefined>()
	const [currentPriorityRowData, setCurrentPriorityRowData] = useState<PriorityType | null>(null)

	const save = usePostIncidents()
	// const organizationsList = useFetchAllHandbookOrganizationType()
	// const divisionList = useFetchAllHandbookDivision()
	// const teamsList = useFetchAllHandbookTeam()
	// const peopleList = useFetchAllHandbookPeople()
	// const processList = useFetchAllHandbookProcess()

	const { data: priorityData } = useFetchAllHandbookPriotityQuery()
	const { data: organizationsList } = useFetchAllHandbookOrganizationQuery()
	const { data: divisionList } = useFetchAllHandbookDivisionQuery()
	const { data: teamsList } = useFetchAllHandbookTeamQuery()
	const { data: peopleList } = useFetchAllHandbookPeopleQuery()
	const { data: processList } = useFetchAllHandbookProcessQuery()




	const [form] = useForm();
	const [startTimeCheck, setStartTimeCheck] = useState(false);
	const [endTimeCheck, setEndTimeCheck] = useState(false);



	const onFinish: FormProps<IncidentType>['onFinish'] = (values) => {

		const finalValues = {
			...values,
			startTime: values.startTime ? (values.startTime as any).toDate().toISOString() : null,
			endTime: values.endTime ? (values.endTime as any).toDate().toISOString() : null,
			firstResponsibleId: values.firstResponsibleId
				? values.firstResponsibleId.map((idObj) => ({ id: idObj }))
				: [],
			secondResponsibleId: values.secondResponsibleId
				? values.secondResponsibleId.map((idObj) => ({ id: idObj }))
				: [],
			thirdResponsibleId: values.thirdResponsibleId
				? values.thirdResponsibleId.map((idObj) => ({ id: idObj }))
				: [],
			team: values.team
				? values.team.map((idObj) => ({ id: idObj }))
				: [],
			directorId: [{ id: values.directorId }],
			responsibleId: values.responsibleId
				? values.responsibleId.map((idObj) => ({ id: idObj }))
				: [],
			divisions: values.divisions
				? values.divisions.map((idObj) => ({ id: idObj }))
				: [],
			address: '321',
			incidentProcess: values.incidentProcess
				? values.incidentProcess.map((idObj) => ({ id: idObj }))
				: [],
			victims: values.victims
				? values.victims.map((idObj) => ({ id: idObj }))
				: [],
			perpetrators: values.perpetrators
				? values.perpetrators.map((idObj) => ({ id: idObj }))
				: [],
			priority: currentPriorityRowData ? { id: currentPriorityRowData.id } : null,
			overallStrategyOrApproachIncident: "fsfds",
		};


		console.log('@VALUES: ', values.incidentProcess)

		save.mutate(finalValues);

		console.log('@VALUES: ', finalValues)
	};

	const priorityColumns = [
		{
			title: 'Наименование приоритета',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Цвет',
			dataIndex: 'color',
			key: 'color',
			render: (color: string) => (
				<div
					style={{
						width: '24px',
						height: '24px',
						borderRadius: 6,
						backgroundColor: color,
						border: '1px solid #ccc',
					}}
				></div>)
		},
		{
			title: 'Циферное обозначение',
			dataIndex: 'number',
			key: 'number',
		},
		{
			title: 'Наименование серьезности',
			dataIndex: 'seriousTitle',
			key: 'seriousTitle',
		},
		{
			title: 'Последствия в ден. выражении ($) мин.',
			dataIndex: 'minMonetaryConsequences',
			key: 'minMonetaryConsequences',
		},
		{
			title: 'Последствия в ден. выражении ($) макс.',
			dataIndex: 'maxMonetaryConsequences',
			key: 'maxMonetaryConsequences',
		},
		{
			title: 'Частота инцидента в месяц, мин',
			dataIndex: 'minIncidentFrequency',
			key: 'minIncidentFrequency',
		},
		{
			title: 'Частота инцидента в месяц, макс',
			dataIndex: 'maxIncidentFrequency',
			key: 'maxIncidentFrequency',
		},
		{
			title: 'Время реакции макс в секундах',
			dataIndex: 'timeReactionSeconds',
			key: 'timeReactionSeconds',
		},
		{
			title: 'Время закрытия макс в секундах',
			dataIndex: 'maxTimeReactionSeconds',
			key: 'maxTimeReactionSeconds',
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
		},
	];
	useEffect(() => {
		let isMounted = true; // Флаг для проверки монтирования компонента

		const processData = () => {
			if (
				organizationsList &&
				teamsList &&
				peopleList &&
				divisionList
			) {
				setLoading(true);

				const organizations = (organizationsList || [])
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: item.title }));

				const teams = (teamsList || [])
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: item.title }));

				const people = (peopleList || [])
					.filter((item) => item.id !== undefined)
					.map((item) => ({
						id: item.id as number,
						title: `${item.firstName} ${item.middleName}`,
					}));

				const divisions = (divisionList || [])
					.filter((item) => item.id !== undefined)
					.map((item) => ({ id: item.id as number, title: item.title }));

				if (isMounted) {
					setList([...organizations, ...teams, ...people]);
					setGroup([...organizations, ...teams]);
					setPlace([...organizations, ...divisions]);
					setLoading(false);
				}
			}
		};

		processData();

		return () => {
			isMounted = false; // Сброс флага при размонтировании компонента
		};
	}, [
		organizationsList,
		teamsList,
		peopleList,
		divisionList,
	]);

	return (

		<Space direction="vertical" size="middle" style={{ display: 'flex', padding: 20 }}>

			<Typography.Title level={2}>Создание заявки</Typography.Title>

			<Form
				name="basic"
				labelAlign="left"
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 18 }}
				onFinish={onFinish}
				autoComplete="off"
				form={form}
				style={{ minWidth: 1200 }}
			>

				<Form.Item<IncidentType>
					label="Автор заявки"
				>
					<Input />
				</Form.Item>

				<Form.Item<IncidentType>
					label="Состояние заявки"
					name='status'
				>
					<Select options={[
						{ value: 'CREATED', label: 'Создана' },
						{ value: 'CLOSED', label: 'Закрыта' }
					]}

					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Проблема"
					name='problem'
				>
					<Input.TextArea />
				</Form.Item>

				<Form.Item<IncidentType>
					label="Решить и закрыть до"
					name='endTime'
				>
					<DatePicker showTime format='YYYY-MM-DD HH:mm' />
				</Form.Item>
				<Typography.Title level={5}>Роли и/или ответственность</Typography.Title>

				<Form.Item<IncidentType>
					label="Ответственные на первой линии"
					name="firstResponsibleId"
				>
					<Select
						loading={loading}
						mode='multiple'

						options={list?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Ответственные на второй линии"
					name="secondResponsibleId"
				>
					<Select
						loading={loading}
						mode='multiple'

						options={list?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Ответственные на третьей линии"
					name="thirdResponsibleId"
				>
					<Select
						mode='multiple'
						loading={loading}

						options={list?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Спец. группа расследования"
					name="team"
				>
					<Select
						mode='multiple'
						loading={loading}

						options={group?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Руководитель или лидер спец группы рассл"
					name="directorId"
				>
					<Select
						loading={loading}

						options={list?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Form.Item<IncidentType>
					label="Ответственные за мониторинг или контроль"
					name="responsibleId"
				>
					<Select
						loading={loading}
						mode='multiple'

						options={list?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Typography.Title level={5}>Место, где произошел инцидент или есть подозрения на него </Typography.Title>

				<Form.Item<IncidentType>
					label="Физическое"
					name="divisions"

				>
					<Select
						loading={loading}
						mode='multiple'
						options={place.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>


				<Form.Item<IncidentType>
					label="Система"
					name="system"
				>
					<Select />
				</Form.Item>

				<Form.Item<IncidentType>
					label="Процесс"
					name="incidentProcess"
				>
					<Select
						mode='multiple'
						options={processList?.map((item) => ({
							label: item.title,
							value: item.id,
						}))}
					/>
				</Form.Item>

				<Typography.Title level={5}>Время инцидента или его проишествия </Typography.Title>

				<Form.Item label={startTimeCheck ? 'Известно' : 'Неизвестно'} name="startTimeCheck" valuePropName="checked">
					<Checkbox onChange={(e) => setStartTimeCheck(e.target.checked)} />
				</Form.Item>
				{startTimeCheck && (
					<Form.Item label="Начало" name="startTime" rules={[{ required: true, message: 'Выберите дату!' }]}>
						<DatePicker showTime format="YYYY-MM-DD HH:mm" />
					</Form.Item>
				)}


				<Form.Item<IncidentType> label={endTimeCheck ? 'Известно' : 'Неизвестно'} name="endTimeCheck" valuePropName="checked">
					<Checkbox onChange={(e) => setEndTimeCheck(e.target.checked)} />
				</Form.Item>
				{endTimeCheck && (
					<Form.Item<IncidentType> label="Конец" name="endTime" rules={[{ required: true, message: 'Выберите дату!' }]}>
						<DatePicker showTime format="YYYY-MM-DD HH:mm" />
					</Form.Item>
				)}




				<Form.Item<IncidentType>
					label="Общее описание сути инцидента"
					name="description"
				>
					<Input.TextArea />
				</Form.Item>

				{/* <Space style={{ display: 'flex', marginBottom: 8, justifyContent: 'space-between', width: '100%', alignItems: 'flex-start' }} align="baseline"> */}

				<Flex justify='space-between' align='flex-start'>

					<Flex vertical style={{ width: 500 }} gap={'middle'}>

						<Form.Item<IncidentType>
							label="Пострадавшие"
							name="victims"
						>
							<Select
								mode='multiple'
								loading={loading}

								options={list?.map((item) => ({
									label: item.title,
									value: item.id,
								}))}
							/>
						</Form.Item>
						<Form.Item<IncidentType>
							label="Виновные лица"
							name="perpetrators"
						>
							<Select
								loading={loading}
								mode='multiple'

								options={list?.map((item) => ({
									label: item.title,
									value: item.id,
								}))}
							/>
						</Form.Item>
					</Flex>

					<Flex vertical align='flex-start'>
						<Typography.Title level={5}>	Сумма потенциальных и/или фактиеских потерь в $ </Typography.Title>


						<Form.Item<IncidentType> name='minAmountPotentialActualLosses' label="От" rules={[{ type: 'number', min: 0 }]}>
							<InputNumber />
						</Form.Item>

						<Form.Item<IncidentType> name='maxAmountPotentialActualLosses' label="До" rules={[{ type: 'number', min: 0 }]}>
							<InputNumber />
						</Form.Item>
					</Flex>
					{/* </Space> */}
				</Flex>


				{/* <ActionTable /> */}

				{/* <ReasonsTable /> */}

				<Typography.Title level={5}>Общая стратегия или подход решения инцидента</Typography.Title>


				<Form.Item<IncidentType> name='overallStrategyOrApproachIncident' >
					<Input.TextArea />
				</Form.Item>
				<Typography.Title style={{ marginTop: 12 }} level={5}>Класс и приоритет инцидента</Typography.Title>

				<Table
					style={{ marginTop: 20 }}
					columns={priorityColumns}
					dataSource={priorityData}
					rowKey={(data: PriorityType) => Number(data.id)}
					onRow={(record) => {
						return {
							onClick: () => {
								setSelectedPriorityRowId(record.id)
								setCurrentPriorityRowData(record)
							},
						};
					}
					}
					rowClassName={(record) => (record.id === selectedPriorityRowId ? 'pickedTableRow' : 'defaultTableRow')}
				/>

				<FloatButton
					shape="square"
					style={{ right: 24, bottom: 90 + 24 }}
					icon={<FontAwesomeIcon icon={faArrowLeft} />}
					onClick={() => {
						// navigate(`${URLPaths.MODEL_EDIT.link}/${fromModel}`)
					}}
				/>

				<FloatButton
					icon={<SaveOutlined />}
					onClick={() => form.submit()}
				/>


			</Form>
		</Space>
	)
}

export default ManualIncidentCreate