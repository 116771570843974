import { Button, Flex, Form, FormProps, Input, InputNumber, Modal, Select, Table, TableProps, Typography } from 'antd';
import React, { useState } from 'react';
import styles from './ReasonsTable.module.scss'

interface DataType {
	key: string;
	symptom: string;
	description: string;
	reason: string;
	keys: string;
	children: Array<ChildRowType> | null;
}

type ChildRowType = {
	decision: string;
	operation: string;
	responsible: string[];
	time: number;
	key: string
};

type FieldType = {
	symptom: string;
	description: string;
	reason: string;
	keys: string;

	decision?: string;
	operation?: string;
	responsible?: Array<string>
	time?: number
};


function ReasonsTable() {
	const [dataSource, setDataSource] = useState<DataType[]>([]);
	const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();

	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {

		// const newItem: DataType = {
		// 	key: `${Date.now()}`,
		// 	symptom: values.symptom,
		// 	description: values.description,
		// 	reason: values.reason,
		// 	keys: values.keys,
		// 	children: {
		// 		decision: values.decision,
		// 		operation: values.operation,
		// 		responsible: values.responsible,
		// 		time: values.time
		// 	}
		// };

		// setDataSource((prev) => Array.isArray(prev) ? [...prev, newItem] : [newItem]);

		setIsModalOpen(false);
	};

	const addRow = () => {
		setDataSource((prev) => [
			...prev,
			{
				key: `${Date.now()}`,
				symptom: '',
				description: '',
				reason: '',
				keys: '',
				children: null
			},
		]);
	};

	const removeRow = () => {
		if (selectedRowIndex === null) return;

		// Фильтруем элементы, исключая выбранный индекс
		const newDataSource = dataSource.filter((_, index) => index !== selectedRowIndex);

		// Обновляем состояние
		setDataSource(newDataSource);

		// Сбрасываем выбранный индекс
		setSelectedRowIndex(null);
	};

	const addChildRow = () => {
		if (selectedRowIndex === null) return;

		setDataSource((prevDataSource) => {
			// Создаем новый массив данных
			return prevDataSource.map((row, index) => {
				if (index === selectedRowIndex) {
					return {
						...row,
						children: [
							...(row.children || []), // Копируем существующих детей
							{
								key: `${Date.now()}`, // Уникальный ключ для новой строки
								decision: '',
								operation: '',
								responsible: [],
								time: 0,
							},
						],
					};
				}
				return row; // Возвращаем остальные строки без изменений
			});
		});
	};


	const columns: TableProps<DataType>['columns'] = [
		{
			title: 'Симптом или признак',
			dataIndex: 'symptom',
			key: 'symptom',
			render: (text, _, index) => {
				return <Input.TextArea style={{ width: '100%' }} />
			},
		},
		{
			title: 'Описание',
			dataIndex: 'description',
			key: 'description',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
		{
			title: 'Корневая причина',
			dataIndex: 'reason',
			key: 'reason',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
		{
			title: 'Ключевые',
			dataIndex: 'keys',
			key: 'keys',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
	];

	const childrenColumns: TableProps<ChildRowType>['columns'] = [
		{
			title: 'Подход решения',
			dataIndex: 'decision',
			key: 'decision',
			render: (text, _, index) => {
				return <Input.TextArea style={{ width: '100%' }} />
			},
		},
		{
			title: 'Действия и операции',
			dataIndex: 'operation',
			key: 'operation',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
		{
			title: 'Ответственные',
			dataIndex: 'responsible',
			key: 'responsible',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
		{
			title: 'Норматив времени на исполнение в минутах',
			dataIndex: 'time',
			key: 'time',
			render: (text, _, index) => {
				return <Input style={{ width: '100%' }} />
			},
		},
	];

	return (
		<Flex vertical style={{ marginTop: 20 }}>
			<Flex justify='space-between' style={{ marginBottom: 8 }}>
				<Typography.Title level={5}>
					Причины, симптомы, признаки и последствия инцидента
				</Typography.Title>
				<Flex gap='middle'>
					<Button onClick={addRow}>+</Button>
					<Button onClick={removeRow}>
						-
					</Button>
				</Flex>
			</Flex>

			<Table<DataType>
				columns={columns}
				dataSource={dataSource}
				key={dataSource.length}
				rowClassName={(record, index) =>
					selectedRowIndex === index ? styles.picked : styles.default
				}
				onRow={(_, index) => ({
					onClick: () => {
						setSelectedRowIndex(index !== undefined ? index : null);
					},
				})}
				expandable={{
					expandedRowRender: (record) => <Flex vertical>
						<Flex gap={'middle'}>
							<Button onClick={addChildRow} disabled={selectedRowIndex === null}>+</Button>
							<Button disabled={selectedRowIndex === null} onClick={() => { console.log('DataSource:', dataSource) }}>-</Button>
						</Flex>
						<Table
							columns={childrenColumns}
							dataSource={record.children || []}
							pagination={false}
							rowKey={(record) => record.key}
						/>
					</Flex>,

				}}
			/>

		</Flex>
	);
}

export default ReasonsTable;
