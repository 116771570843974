import { Button, Flex, Input, InputNumber, Select, Table, TableProps, Typography } from 'antd';
import styles from './ActionTable.module.scss';
import React, { useState } from 'react';

interface DataType {
	key: string;
	actions: string;
	responsible: string[];
	time: number;
}

function ActionTable() {
	const [dataSource, setDataSource] = useState<DataType[]>([]);
	const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

	const deleteRow = () => {
		if (selectedRowIndex !== null) {
			setDataSource((prev) => {
				const newData = prev.filter((_, index) => index !== selectedRowIndex);
				return newData;
			});
			setSelectedRowIndex(null); // Сбросить выбранный индекс
		}
	};

	const columns: TableProps<DataType>['columns'] = [
		{
			title: 'Действия',
			dataIndex: 'actions',
			key: 'actions',
			width: '40%',
			render: (_, record) => (
				<Input.TextArea
					style={{ width: '100%' }}
					placeholder="Опишите действия"
					defaultValue={record.actions}
				/>
			),
		},
		{
			title: 'Ответственные',
			dataIndex: 'responsible',
			key: 'responsible',
			width: '30%',
			render: (_, record) => <Select style={{ width: '100%' }} defaultValue={record.responsible} />,
		},
		{
			title: 'Норматив времени на исполнение в минутах',
			dataIndex: 'time',
			key: 'time',
			width: '30%',
			render: (_, record) => (
				<InputNumber style={{ width: '100%' }} defaultValue={record.time} />
			),
		},
	];

	const addRow = () => {
		setDataSource((prev) => [
			...prev,
			{
				key: `${Date.now()}`,
				actions: '',
				responsible: [],
				time: 10,
			},
		]);
	};

	return (
		<Flex vertical>
			<Flex justify="space-between" style={{ marginBottom: 8 }}>
				<Typography.Title level={5}>
					Действия по анализу и/или расследованию
				</Typography.Title>
				<Flex gap="middle">
					<Button onClick={addRow}>+</Button>
					<Button onClick={deleteRow} disabled={selectedRowIndex === null}>
						-
					</Button>
				</Flex>
			</Flex>

			<Table<DataType>
				columns={columns}
				dataSource={dataSource}
				rowKey="key"
				onRow={(_, index) => ({
					onClick: () => {
						setSelectedRowIndex(index !== undefined ? index : null);
					},
				})}
				rowClassName={(record, index) =>
					selectedRowIndex === index ? styles.picked : styles.default
				}
			/>
		</Flex>
	);
}

export default ActionTable;
