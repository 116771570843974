import { Button, Flex, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { URLPaths } from '../../config/application/URLPaths'
import { incidentColumns } from './IncidentsCols'
import { useFetchAllIncidentsQuery } from '../../shared/queries/handbook/incidents/useIncidents'

function Incidents() {


	const navigate = useNavigate()
	const { data } = useFetchAllIncidentsQuery()


	return (
		<div style={{ padding: 20, width: '100%' }}>
			<Flex justify='flex-end' style={{ marginBottom: 20 }}>
				<Button onClick={() => navigate(URLPaths.MANUAL_INCIDENTS.link)}>Создание заявки</Button>
			</Flex>
			<Table
				style={{ width: '100%' }}
				columns={incidentColumns}
				dataSource={data}
			/>
		</div>
	)
}

export default Incidents