import { AxiosRequestConfig } from "axios";
import { api } from "../../../instance";

interface RequestHandbookOrganizationParams {
	params?: { id: string };
	config?: AxiosRequestConfig;
	id?: number
}

export const postHandbookOrganization = async ({ config }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.post<OrganizationType>('/core-service/api/incident/organization', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookOrganization = async ({ config }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.put<OrganizationType>('/core-service/api/incident/organization', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookOrganizationById = async ({ id }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.get<OrganizationType>(`/core-service/api/incident/organization/${id}`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookOrganizationAll = async () => {
	try {
		const response = await api.get<OrganizationType[]>(`/core-service/api/incident/organization/all`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const deleteHandbookOrganization = async ({ id }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.delete<OrganizationType>(`/core-service/api/incident/organization/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

// DIVISIONS

export const deleteHandbookDivision = async ({ id }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.delete<IDivision>(`/core-service/api/incident/division/${id}`)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const postHandbookDivision = async ({ config }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.post<IDivision>('/core-service/api/incident/division', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const putHandbookDivision = async ({ config }: RequestHandbookOrganizationParams) => {
	try {
		const response = await api.put<IDivision>('/core-service/api/incident/division', { ...config })
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}

export const getHandbookDivisionAll = async () => {
	try {
		const response = await api.get<IDivision[]>(`/core-service/api/incident/division/all`,)
		return response.data;
	}
	catch (err) {
		let errorMessage = 'An error occurred';

		if (err instanceof Error) {
			errorMessage = err.message;
		} else if (typeof err === 'object' && err !== null && 'response' in err) {
			const response = (err as any).response;
			if (response && response.data && response.data.message) {
				errorMessage = response.data.message;
			}
		}
		return Promise.reject({ message: errorMessage });
	}
}
